import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">20 X 20 / 下半場</h2>
          <h1 className="main_title">主持人評論與討論回應</h1>
        </div>

        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_5">
              <div className="context">

                <h4>主持人評論</h4>
                <p>龔書章認為公共藝術在法規、招標等比起建築皆更有開放性與自由，定義應更有機會去打破、更有彈性。這場先期公共藝術的論述因此需要被更清晰地看到，並邀建築師來一同參與。</p>
                <p>黃姍姍的發表很震撼。身為策展人的黃姍姍，每天都在面對策展、面對藝術家，她的提問是重要的──公共藝術的作品需要再多一個還是少一個。是否該作為是一個重要的概念。</p>
                <p>蔡淳任提出的概念很有意思，從表演藝術聯盟的角度出發，詢問為什麼公共藝術不是做一個持續發展的，拉出時間上的長度。而這樣的事需要有計劃，很多委員都在討論，不是沒有可能的。公共藝術讓社區或環境在公共性方面有機會藉由這1%持續發展，會是一個未來面對社宅的重要概念。</p>
                <p>三明治工分享了很多的案例，如此的公民行動和公民教育，如摺飛機、小孩子對公園的看法、對無障礙環境的概念。社會住宅即是需要公民行動，例如剛剛說的投票箱，臺灣人對投票很熱衷，臺灣對投票的公民意識在社區空間裡是怎麼被看待。他們的藝術創作展覽，將藝術轉譯對應真實的生活場域。</p>
                <p>方彥翔則具有不一樣的看法。每一個概念都是社區要面對的問題，背後具有批判或分享，以生活的概念來談，雖然分享的案例只存在於美術館，龔書章皆能想像如果在一個社區，用別的方式去面對清晰問題意識時，有機會成為另一個改變社區的事。目前為止，這些概念意識沒有被清晰地呈現在公共藝術的作品裡，希望接下來年輕的一代可以多加入公共事務。</p>
                <p>最後一個提到因地制宜則是可以在孫育晴提到許多的案例中看到。都在談及「大家」的想像，如寶藏巖、藝術村，而社宅公共藝術中「大家」是很重要的，也回應了方彥翔提到如何享受孤獨，或是如何讓孤獨變成一種非孤獨的事。</p>
                <p>龔書章最後提及在公共藝術審查會或社會住宅建築的審查會時，很少建築師提到在當代裡，可以享受自我，又可以享受大家的概念，如果有機會應該是社會住宅很大的想像。</p>

                <h4>討論</h4>

                <h6>褚瑞基：</h6>
                <p>首先，建構一個好的平台讓大家一起合作，是我們要面對和檢討的。第二，我們需要更有創意的機制，需要有第三軌，當我們用公開徵選、邀請比件等等遇到困難時，是不是有新的方式脫離這個機制，找到更好的定位。</p>
                <p>他期待可以修法，在美國有機制是保留25%給年輕創作者，而不是直接委託專業，這需要政府的認同。消失的藝術合各種的不同的案例都可以做，累積這些資料後，我們期待未來可以做更好。</p>

                <h6>健康社宅青創計畫居民：</h6>
                <p>健康公宅內有很多不同的青創回饋計畫，有物理治療、律師顧問、料理、手作、社區服務300小時等，許多讓社區生活對話的方式。</p>
                <p>第一個情況為目睹社區野餐日，青創戶的媽媽帶著有ADHD小孩，因小孩活動情緒失控，媽媽開始打小孩，有路人勸阻也有人在錄影。看到這個情況反思到自己的藝術計畫無法解決、呼應這樣臨時的狀況。透過藝術能否去回應這些社區問題？</p>
                <p>第二個情況則是社宅委託的景觀公司持續於一個無光照的空間，不斷植樹，樹因此無法長活。最後終於鋪了石頭，卻排成愛心形狀。居民因此發現每個人的美學概念不同，無法要求每個人對社會住宅空間美學和環境意識一致，是否有機制能讓景觀問題被解決。</p>

                <h6>龔書章：</h6>
                <p>共同生活體有許多不同族群，在臺灣需要處理如何在單一創作、思考的情況中有更多創意的思考。</p>
                <p>他舉例美國有個公園有很多遊民，這個公園卻剛好是捷運和社區間的必經之路。政府的立場，不能把無害的遊民趕走，但社區的家長會擔心孩子路途上的人身危險，因此是要照顧遊民或是擔心孩子的雙親，成為政府的兩難。這時需要某些共創團體一起思考解決方法。面臨多樣性的環境，絕對有狀況必須被解決，無論是以空間、景觀、計畫的方式。政府後來的解決方式是宣布為該市重要寵物公園。因為友善的寵物帶來安全感，帶進人群後形塑人與人之間的安全感。遊民還是留在原地，但是移動到自己安心角落，這個空間並沒有被改造，卻創造了共存性的空間。</p>
                <p>在藝術創作的背後可以有心理學家、為社會服務的工作者共同協作，在臺灣卻很少看到把許多不同的「人種」匯聚在一起。社會住宅最需要這種合作，因為現在的社會住宅充滿了矛盾、不信任的狀態，有著不同族群的共處，以及複雜的社區。</p>

                <h6>李萬鏗：</h6>
                <p>回應方彥翔提到座談會前一天，香港太空館用雷射筆的燈光行動，他覺得它震撼的地方，不僅止於這樣景觀和精神狀態。</p>
                <p>雷射筆的燈光行動利用太空館空間的特性──圓形圍繞的空間，大家圍著這個空間打光。它不是一邊是警察，一邊是人民，而是人民、警察在向心的狀態。這種公共藝術怎麼產生？除了對於現在局勢的敏感，更是從抗爭累積出來。</p>
                <p>在這個抗爭中，在城市生活的人，以這個行動諷刺警察，比抗爭本身還深沉，是對權力的諷刺，諷刺極權對於人性的腐敗。呈現的是一個對於人性的昇華，我覺得很精彩。這就是群眾的公共藝術，很有意義。</p>

                <h6>彭永翔：</h6>
                <p>混居在國外如何處理，在韓國是透過空間的區隔，但臺灣的狀況並未針對空間處理混居，如果在公共藝術、社區營造、社區介入時，老師們是否有什麼國內外的案例，真的把不同族群融合在一起時，這件事是否可透過公共藝術或社區營造解決？</p>

                <h6>褚瑞基：</h6>
                <p>混居體是有的，其中一個是不同族群有一定的比例混在不同的居住空間中。現在的混居一個是由政府自行管理，另外即是請代管單位。如果有嚴重的案例，通常是直接申報到內政部的委員會。</p>
                <p>有一案例情況為社區的人得知隔壁有問題者，透過通訊軟體傳播出去，幾乎造成混居的崩解。這源自於我們都在分割我們自己與其他人，現代社會中我們了解並試圖同理，但當自身遇到如此情況時卻仍然會表現得很激烈。</p>
                <p>台灣幾乎沒有處理混居問題的前例，希望軟性的公共藝術可以想辦法解決。我們所賦予公共藝術的角色是很複雜的，也希望可以有一整個團隊長期駐進。希望公共藝術可以成為調和的角色。</p>

                <h6>彭永翔：</h6>
                <p>如此的團隊似乎需要囊括不同領域的人，才能解決如此的問題。</p>

                <h6>褚瑞基：</h6>
                <p>以台中社宅的案子為例，前端有社會學、人類學的人才，這樣的組成讓我們抱有很大的期待。然而到了政府機制彈性較低，提出的方案都很難通過。</p>

                <h6>龔書章：</h6>
                <p>討論公共生活前要先討論隱私。不要以為大家都想一起生活，很多人是享受自己的生活。隱私很重要，要享受自己生活又在一個大眾群體，有時候空間無法解決。在定義Privacy和Publicity，做空間與做社會服務的人有所不同。目前臺灣的社宅，兩邊還在互相的對話。</p>
                <p>就緊急處理機制，在區分動線或狀況發生時，如何建立一道隱形的區隔？目前臺灣的社宅還沒有細膩的討論如何在社群間保留自我又可以同時共生。台灣常常不小心就干擾別人，這也是台灣人的熱情。</p>

                <h6>褚瑞基：</h6>
                <p>公宅設計已經開始有標準化。在日本看過很多共享空間，如Maker Space，他是一個非常好的動線聚集點。他不一定是高科技，也可以是一個傳統的low craft學習，重要的是如何透過空間的經營讓大家有共同的興趣。然而，都發局可能詢問是要讓誰來經營？</p>

                <h6>龔書章：</h6>
                <p>目前社會局也沒有這種經營模式。以前公共住宅的社福單位是分區塊，現在則是layer的概念。把社福機構放在第三層，第二層是很多緩衝角色，最前面那層是很活動性的。社福醫療躲在背後，隨時可以接近社群，但不要被干擾、看到，不再是社福醫療一區、娛樂一區的概念，而是變化成社福機構藉由共創中心來支撐。需要有一群人來研究這種新模式，處理社群關係。目前的空間只處理到簡單的分割，但空間其實可以細膩處理人跟人的關係。 </p>

              </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_2_1" className="next_page">
            <div className="next_title">傳承創新</div>
            <div className="next_owner">社宅公共藝術咖啡桌 / 第一輪</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
